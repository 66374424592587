import React from "react"
import styled from "@emotion/styled"
import NavigationBar from "../components/locator/navigation-bar"
import Map from "../components/locator/map"
import Product from "../components/locator/product"
import ProductHeader from "../components/locator/product-header"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import { mq, colors, typography, padding } from "../lib/presets"
import LocatorProvider from '../providers/locator'
import Button from '../styles/buttons'
import { P } from '../styles/p'
import StoreLinks from "../components/locator/store-links"

const StoreLocatorContainer = styled(`div`)({
  display: `flex`,
  width: `100%`,
  height: `100vh`,
  flexDirection: `column`,
})

const MapStoreListContainer = styled(`div`)(
  {
    [mq.desktop]: {
      height: `100%`,
      display: `flex`,
      flexGrow: 1,
      flexDirection: `row-reverse`,
    }
  }
)

const SideBarContainer = styled(`div`)(
  {
    display: `flex`,
    flexDirection: `column`,
    backgroundColor: colors.background.gray,
    [mq.desktop]: {
      marginTop: 0,
      paddingTop: 0,
      minWidth: `25em`,
      maxWidth: `25em`,
    }
  }
)

const StoreAddressContainer = styled(`p`)({
  fontFamily: typography.body.family,
  fontStyle: `normal`,
  fontSize: `1.1em`,
  marginTop: `0.1em`,
  color: colors.text.medium,
  lineHeight: 1.4,
})

const StoreLocator = ({ data }) =>
<>
  <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <script src="https://kit.fontawesome.com/413198f958.js"></script>
    <link href="https://fonts.googleapis.com/css?family=Arvo:400,700&display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="https://use.typekit.net/xnb5eqk.css" />
  </Helmet>
  <SEO />
  <LocatorProvider>
  <StoreLocatorContainer>
  {/*}
    <NavigationBar showBackButton={true} store={data.store}/>
    <MapStoreListContainer>
      <Map contentfulStore={data.store} />
      <SideBarContainer>
      <StoreAddressContainer>
        <P style={{padding: padding.small}}>
        {data.store.address.lineOne}<br />
        {data.store.address.city}, {data.store.address.state} {data.store.address.zipCode}
        </P>
      </StoreAddressContainer>
      <StoreLinks store={data.store} css={{ marginLeft: padding.small }} hideProductsLink />
      { data.store.products.length > 0 && <ProductHeader count={data.store.products.length} /> }
      { data.store.products.length > 0 && (
        data.store.products.map(product => <Product product={product} key={product.name} /> )
      )}
      </SideBarContainer>
    </MapStoreListContainer>
  */}
  </StoreLocatorContainer>
  </LocatorProvider>
</>

export default StoreLocator

export const query = graphql`
query Store($slug: String!) {
  store: contentfulStore(slug: { eq: $slug }) {
    id
    name
    phoneNumber
    slug
    address {
      city
      state
      lineOne
      zipCode
    }
    location {
      lat
      lon
    }
    products {
      name
      shopifyLink
      image {
        fluid(maxWidth: 200) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  }
}
`
